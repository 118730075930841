'use client';

import { useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { type NextPage } from 'next';
import { useRouter } from 'next/navigation';
import { toast } from 'react-toastify';

import Layout from '@/components/Layout';
import Spinner from '@/components/svg/spinner';
import { COUNTRY_CODES } from '@/constants/CountryCodes';
import { Auth0Service } from '@/services/auth0';
import { SuppliersService } from '@/services/suppliers';

const Login: NextPage = (): JSX.Element => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const { mutate: validateUser, isLoading: isValidating } = SuppliersService.useUserValidation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const phone = event.target.cr.value + event.target.phone.value;

    validateUser(
      { phone_number: `${phone}` },
      {
        onSuccess: async res => {
          if (res.exists) {
            await Auth0Service._passwordlessStart(phone)
              .then(() => {
                router.push(`/auth/otp?phone=${phone}`);
              })
              .catch(error => {
                Sentry.captureException(error);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            toast.info('Este número no se encuentra registrado', {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          }
        },
        onError: error => {
          Sentry.captureException(error);
        },
      },
    );
  };

  const handleGoBack = () => {
    router.push(`/`);
  };

  return (
    <Layout>
      <div className="flex flex-col text-center space-y-6 pb-10">
        <span className="text-7xl font-extrabold">Alisto</span>
        <span className="max-w-md">
          Proveedores usan Alisto para crecer su negocio sin costos extras conectando con clientes que buscan tus
          ingredientes
        </span>
      </div>
      <div className="py-6 px-11 max-w-md bg-white shadow-md drop-shadow-md flex items-center space-x-4">
        <div className="max-w-md w-full space-y-10">
          <div>
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">Iniciar sesión</h2>
          </div>
          <form className="mt-8 space-y-3" onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="phone">Teléfono del negocio (Ej: +50688885050)</label>

                  <div className="flex">
                    <select
                      name="cr"
                      className="py-2 px-3 border border-gray-300 shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm mr-3 bg-lightgrey-100"
                    >
                      {COUNTRY_CODES.map((country, index) => (
                        <option key={index} value={country.dial_code}>
                          {country.dial_code}
                        </option>
                      ))}
                    </select>
                    <input
                      id="phone"
                      name="phone"
                      type="phone"
                      autoComplete="phone"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 h-14 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:z-10 sm:text-md bg-lightgrey-100"
                      placeholder="Ingrese teléfono"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex pt-6 justify-center">
              <button
                type="submit"
                disabled={loading || isValidating}
                className="w-10/12 group relative w-full flex justify-center py-2 px-4 border border-transparent text-2xl font-medium rounded-md text-white bg-brand-500 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand-500 items-center"
              >
                {(loading || isValidating) && <Spinner />}
                Siguiente
              </button>
            </div>
            <div className="text-xs text-center" onClick={handleGoBack}>
              <span className="text-blue-400 decoration-blue-400 underline cursor-pointer">← Regresar</span>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
